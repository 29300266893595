.main {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  margin-top: 80px;
  align-items: center;
  font-family: 'Dejavu sans'
}
.main > * {
  margin-top: 8px;
}
